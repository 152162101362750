/**
 * A simple implementation for event handlers.
 * Consider using any other method such as using Redux or React Context before using this method
 */


export default class Signal<PAYLOAD> {
    private readonly callbacks: Array<(payload?: PAYLOAD) => void> = []

    add(callback: (payload?: PAYLOAD) => void) {
        this.callbacks.push(callback)
    }

    remove(callback: (payload?: PAYLOAD) => void) {
        const index = this.callbacks.indexOf(callback)

        if (index > -1) {
            this.callbacks.splice(index, 1)
        }
    }

    dispatch(payload?: PAYLOAD) {
        const callbacks = this.callbacks.slice(0)
        callbacks.forEach((callback) => {
            try {
                callback(payload)
            }
            catch (error) {
                console.log(error)
            }
        })
    }
}
